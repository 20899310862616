body,
p,
h2,
h3,
h4,
h5,
h6,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: Helvetica, Arial, sans-serif!important;
}

h1,
h2,
.h1,
.h2 {
  font-family: "Georgia", "Times New Roman", serif!important;
}

p, .p{
  font-weight: lighter!important;
}

video {
  width: 100%;
  height: auto;
}

.crop-height {
  /* max-width: 1200px; /* img src width (if known) */
  max-height: 320px;
  overflow: hidden;
}

.bg-image-cropped {
  /* for small devices */
  /*background-image: url("../public/media/office.jpeg");*/
  /* lt ie8 */
  -ms-background-position-x: center;
  -ms-background-position-y: bottom;
  background-position: center;
  /* scale bg image proportionately */
  background-size: cover;
  /* ie8 workaround - http://louisremi.github.io/background-size-polyfill/ */
  /*-ms-behavior: url(/backgroundsize.min.htc);*/
  /* prevent scaling past src width (or not) */
  /* max-width: 1200px; */
}

.invisible {
  visibility: hidden;
}

.transparent {
  /* trigger hasLayout for IE filters below */
  zoom: 1;
  /* 0 opacity in filters still displays layout */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=10);
  opacity: 0;
}

/* example media query for smaller non-retina devices */
@media only screen and (max-device-width: 600px) and (-webkit-max-device-pixel-ratio: 1),
  only screen and (max-device-width: 600px) and (max-device-pixel-ratio: 1) {
  .bg-image-wedding {
    background-image: url("../public/media/office.jpeg");
  }
}

/* example media query for retina ipad and up */
@media only screen and (min-device-width: 768px) and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min-device-width: 768px) and (min-device-pixel-ratio: 1.5) {
  .bg-image-wedding {
    background-image: url("../public/media/office.jpeg");
  }
}

.bg-video-cropped {
  /* for small devices */
  /*background-image: url("../public/media/office.jpeg");*/
  /* lt ie8 */
  -ms-background-position-x: center;
  -ms-background-position-y: bottom;
  background-position: center;
  /* scale bg image proportionately */
  background-size: cover;
  /* ie8 workaround - http://louisremi.github.io/background-size-polyfill/ */
  /*-ms-behavior: url(/backgroundsize.min.htc);*/
  /* prevent scaling past src width (or not) */
  /* max-width: 1200px; */
}

.video-container {
  width: 100%;
  height: auto;
  position: relative;
}

.video-container video {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  z-index: 0;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .imgVidHeight {
    height: 150px;
    padding-left: 0px;
  }
}